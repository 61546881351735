import styled from 'styled-components';
import { Button } from '@@Components/Buttons/Button';
export const DrawerContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-y: scroll;
`;
export const FiltersContainer = styled.div `
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  height: 100%;
`;
export const SelectWrapper = styled.div `
  margin-right: 10px;
  margin-bottom: 20px;
  width: 207px;
`;
export const FiltersMobileContainer = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const HeaderDiv = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 10px 0;
  box-shadow: 0px 5px 20px #0000000f;
  margin-bottom: 10px;
  background-color: #fff;
  width: 100%;
  height: 40px;
  white-space: nowrap;
`;
export const FooterDiv = styled.div `
  background-color: #fff;
  box-shadow: 0px -5px 20px #0000000f;
  padding: 10px;
  margin-top: 10px;
`;
export const HeaderButton = styled.button `
  font-weight: ${(props) => props.fontWeight};
  margin: ${(props) => props.margin};
  display: flex;
  align-items: center;
  max-width: 160px;
`;
export const IconWrapper = styled.div `
  margin-right: 10px;
`;
export const ContentContainer = styled.div `
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  justify-content: space-between;
  flex: 1;
  overflow-y: scroll;
  justify-content: flex-start;
`;
export const StyledButton = styled.button `
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${(props) => props.fontWeight};
  padding: 0;
  text-align: left;
`;
export const InnerContainer = styled.div `
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${(props) => props.theme.input.borderColors.default};
  padding: 10px 0 10px 0;

  &:last-child {
    border-bottom: none;
  }
`;
export const PriceLabel = styled.p `
  font-weight: bold;
  margin: 0;
`;
export const OptionLabel = styled.p `
  margin: 0;
  padding-top: 5px;
`;
export const BottomButton = styled(Button) `
  align-self: center;
  width: 210px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Only for Safari */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      margin-bottom: 80px;
    }
  }

  /* Only for Firefox */
  @-moz-document url-prefix() {
    margin-bottom: 80px;
  }
`;
export const RemoveFilterButton = styled.button ``;
export const FilterButtonWrapper = styled.div `
  display: flex;
`;
export const FiltersHeaderWrapper = styled.div `
  margin-bottom: 10px;
  display: flex;
`;
export const FiltersHeader = styled.span `
  font-size: 16px;
  font-weight: 700;
  line-height: 34px;
  text-transform: capitalize;
`;
export const FiltersClearButton = styled.button `
  margin-left: 5px;
  font-weight: 600;

  svg {
    display: inline-block;
    margin-left: 5px;
  }
`;
export const ShowMoreButton = styled.button `
  display: flex;
  align-items: center;
  height: 42px;
  padding-bottom: 3px;
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
  column-gap: 10px;
`;
export const FiltersBar = styled.div `
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  padding-top: 3px;
  width: 100%;
`;
export const SpinnerWrapper = styled.div `
  padding-top: 40px;
`;
