import styled from 'styled-components';
export const CategoryPageContainer = styled.div `
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 32px 0 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${(props) => props.theme.mixins.mobile(' padding: 0 15px;')};
  @media (max-width: 499px) {
    padding-right: 0;
  }
`;
export const BreadcrumbsContainer = styled.div `
  padding-top: 2rem;
  ${(props) => props.theme.mixins.mobile('display: none;')};
`;
export const CategoryPageInnerContainer = styled.div `
  width: 100%;
  display: flex;
  padding: 10px 0 40px 0;
  position: relative;
  ${(props) => props.theme.mixins.mobile('padding: 0 0 30px 0;')};
`;
export const CategoryTreeContainer = styled.section `
  display: flex;
  flex-direction: column;
  min-width: 348px;
  ${(props) => props.theme.mixins.mobile('display: none;')};
  @media screen and (max-width: 1430px) {
    min-width: 300px;
    flex-grow: 0;
  }
  @media screen and (max-width: 1375px) {
    min-width: 250px;
  }
  @media screen and (max-width: 1050px) {
    min-width: 340px;
  }
  @media screen and (max-width: 970px) {
    min-width: 300px;
  }
  @media screen and (max-width: 920px) {
    min-width: 280px;
  }
`;
export const ContentContainer = styled.section `
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 60%;
  width: 100%;
  ${(props) => !props.fullWidth && 'max-width: 1019px;'}
  ${(props) => props.theme.mixins.mobile(`
    padding-left: 0;
    margin-top: 25px;
  `)};
`;
export const ContentInnerContainer = styled.div `
  @media (max-width: 499px) {
    padding-right: 15px;
  }
`;
export const PaginationWrapperBottom = styled.div `
  display: flex;
  justify-content: center;
  padding-top: 20px;
  ${(props) => props.theme.mixins.mobile('padding-top: 15px;')}
`;
export const PaginationWrapperTop = styled.div `
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
  @media (min-width: 551px) {
    display: none;
  }
`;
export const FiltersWrapper = styled.div `
  ${(props) => props.theme.mixins.mobile('display: none')};
`;
export const BottomButtonWrapper = styled.div `
  align-self: center;
  position: sticky;
  bottom: 30px;
  z-index: 5;
  width: 210px;
  margin: 20px;
`;
export const IconWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;
export const TextMobile = styled.button `
  ${(props) => props.theme.mixins.mobile('display: flex;')};
  margin-right: 10px;
  margin-top: 10px;
  display: none;
  align-items: center;
  font-weight: 700;
  padding: 0;
`;
export const CategoryDescriptionWrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
export const CategoryDescriptionHeaderText = styled.h1 `
  margin: 0 5px 20px 0;
  font-size: 20px;
  line-height: 2.57rem;
  ${(props) => props.theme.mixins.mobile('margin-bottom: 15px;')}
`;
export const SearchResultsInfo = styled.div `
  display: flex;
  column-gap: 5px;
`;
export const SearchResultsText = styled.h4 `
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
`;
